import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react'
import { useComposable, AccountFormTypes, AccountPageType, LoginForm, RegisterForm } from 'composable'
import AtgForgotPassword from 'composable/components/account/atg-forgot-password'
import AtgLoginPage from 'composable/components/account/atg-login-page'
import { SITE_CONFIG_CONTENT_TYPE, SITE_CONFIG_NAME } from 'composable/components/general'
import useContentstack, { Entry } from 'composable/components/hooks/useContentstack'
import { useFormat } from 'helpers_composable/hooks/useFormat'
import SignUpModal from 'frontastic/tastics/SignupModal'
import { LegacyMegaDrawerItem as MegaDrawerItem } from '../legacy-mega-drawer/legacy-mega-drawer-item'
import OrderStatusModal from 'composable/components/account/pages/account-dashboard/components/order-status'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { capitalizeFirstLetter } from '../../utils/legacy-cart'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { utagLink } from 'helpers/tealiumHelper'
import { useAccount } from 'frontastic'
import { useRouter } from 'next/router'

export interface MegaDrawerProps {
  items: Entry[]
  accountFormToShow?: string
  setAccountFormToShow: any
}

export interface SiteConfig {
  brand_logo_small?: {
    url: string
  }
  name: string
}

export const MegaDrawer = ({ items, accountFormToShow, setAccountFormToShow }: MegaDrawerProps) => {
  const router = useRouter()
  const { userSessionData, fetchUserSessionData, fetchUserData } = useAtgUser()
  const { refreshCart } = useAtgLegacyCart()
  const [siteConfig, setSiteConfig] = useState<SiteConfig>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { megaDrawer, path } = useComposable()
  const { getEntryByName } = useContentstack()
  const { formatMessage } = useFormat({ name: 'common' })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const userName = capitalizeFirstLetter(userSessionData?.firstName)

  const closeModal = () => {
    setIsModalOpen(false)
  }
  const openLoginModal = () => {
    setIsModalOpen(true)
  }
  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isModalOpen) {
      closeModal()
    }
  }

  useEffect(() => {
    getPageConfig()
  }, [])

  useEffect(() => {
    if (megaDrawer.isOpen && !isOpen) {
      setIsOpen(true)
    }
  }, [megaDrawer.isOpen])

  const getPageConfig = async () => {
    const result = await getEntryByName(SITE_CONFIG_CONTENT_TYPE, SITE_CONFIG_NAME)
    setSiteConfig(result)
  }

  const onCloseHandler = () => {
    setIsOpen(false)
    setTimeout(() => {
      megaDrawer.onClose()
    }, 400)
  }

  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false)

  const handleOpenOrderStatusModal = () => {
    setIsOrderStatusModalOpen(true)
  }

  const handleCloseOrderStatusModal = () => {
    setIsOrderStatusModalOpen(false)
  }

  const handleLogout = async () => {
    await atgAxiosInstance.post('currentUser/logout', {})
    fetchUserSessionData()
    fetchUserData()
    refreshCart()
    utagLink(
      [
        'link',
        {
          tealium_event: 'signout',
          crm_customer_number: null,
          customer_id: null,
          user_loginstatus: false,
        },
      ],
      userSessionData?.dxlCountry,
      userSessionData?.dxlCurrency,
    )
    router.push('/')
    onCloseHandler()
  }

  if (!items || !siteConfig) return null

  return accountFormToShow ? (
    <>
      <SignUpModal
        isOpen={accountFormToShow === 'create_account'}
        openSignInModal={() => {
          setAccountFormToShow('login')
        }}
        onClose={() => {
          setAccountFormToShow(null)
        }}
      />
      <AtgLoginPage
        isOpen={accountFormToShow === 'login'}
        onClose={() => {
          setAccountFormToShow(null)
        }}
        toggleForgotPassword={toggleForgotPassword}
      />
      <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
    </>
  ) : (
    <Drawer isOpen={isOpen} onClose={onCloseHandler} placement="left" size={'full'}>
      <DrawerOverlay />
      <DrawerContent height={'100dvh'} width="90% !important" animation="ease-in-out" transition="ease-in-out">
        <DrawerCloseButton
          style={{
            position: 'fixed',
            fontSize: 16,
            right: '-38px',
            top: 0,
          }}
          _hover={{
            bg: 'none',
          }}
          color={'white'}
        />
        <DrawerBody p={0}>
          {userName ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              gap={2}
              py={3}
              px={2}
              mx={3}
              borderBottom={'1px solid #000'}
              fontSize={'14px'}
            >
              <HStack gap={1} width={'100%'}>
                <Text>Hi</Text>
                <Text fontWeight={600}>{userName}</Text>
              </HStack>
              <HStack justifyContent="space-between" width="100%">
                <Link title="Go to my Account" textDecoration="none" href="/myaccount/profile">
                  Go to your account
                </Link>
                <Text title="Sign Out" as="button" onClick={() => handleLogout()}>
                  Sign out
                </Text>
              </HStack>
            </Box>
          ) : (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              height={'3rem'}
              mb={6}
              py={3}
              px={2}
              mx={3}
              borderBottom={'1px solid #000'}
            >
              <Button
                onClick={() => {
                  onCloseHandler()
                  setAccountFormToShow('login')
                }}
                style={{ fontWeight: 400, fontSize: '16px', backgroundColor: 'white', color: 'black', paddingLeft: 0 }}
              >
                Sign In
              </Button>
              <Button
                onClick={() => {
                  onCloseHandler()
                  setAccountFormToShow('create_account')
                }}
                style={{ fontWeight: 400, fontSize: '16px', backgroundColor: 'white', color: 'black', paddingRight: 0 }}
              >
                Create Account
              </Button>
            </Box>
          )}

          <Accordion allowToggle gap={2} display={'flex'} flexDirection={'column'} mx={4}>
            {items?.map((item) => {
              const key = item?.uid
              return (
                <AccordionItem
                  key={key}
                  pt={0}
                  _hover={{
                    bg: 'none',
                  }}
                  borderTop={0}
                  borderBottom={0}
                  height={'2.813rem'}
                >
                  <MegaDrawerItem key={key + '_item'} item={item} closeAll={onCloseHandler} />
                </AccordionItem>
              )
            })}
          </Accordion>

          <Box
            display={'flex'}
            gap={'5px'}
            mt={'var(--chakra-space-4)'}
            justifyContent={'left'}
            alignItems={'center'}
            py={'var(--chakra-space-3)'}
            borderTop={'1px solid #000'}
            onClick={handleOpenOrderStatusModal}
            cursor="pointer"
          >
            <Box fontSize={'14px'} textStyle="body-75" fontWeight={700} mx={4}>
              {formatMessage({ id: 'megamenu.details.orderSearch' })}
            </Box>
          </Box>
          {isOrderStatusModalOpen && (
            <OrderStatusModal isOpen={isOrderStatusModalOpen} onClose={handleCloseOrderStatusModal} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
