import { useRef } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionItem,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link as ChakraLink,
  Text,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { getHrefFromTransformedEntryData } from 'atg/utils'
import { useComposable, MEGA_MENU_ITEM, MEGA_MENU_ITEM_GROUP } from 'composable'
import { MegaDrawerItem } from 'composable/components/mega-drawer/MegaDrawerItem'
import { MegaDrawerItemGroup } from 'composable/components/mega-drawer/MegaDrawerItemGroup'
import { utagLink } from 'helpers/tealiumHelper'
import { useFormat } from 'helpers_composable/hooks/useFormat'
import { useAtgUser } from 'frontastic/contexts'

interface MegaDrawerItemProps {
  item: any
  closeAll: () => void
  isSubItem?: boolean
}

export const LegacyMegaDrawerItem = ({ item, closeAll, isSubItem }: MegaDrawerItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { path } = useComposable()
  const btnRef = useRef<HTMLButtonElement>(null)
  const { formatMessage } = useFormat({ name: 'common' })

  const children = item?.children
  const hasChildren = children?.length > 0
  const { userSessionData } = useAtgUser()
  const menuItems = []
  const menuItemGroups = []
  children?.map((item: any) => {
    if (item?._content_type_uid === MEGA_MENU_ITEM) {
      menuItems.push(item)
    } else if (item?._content_type_uid === MEGA_MENU_ITEM_GROUP) {
      menuItemGroups.push(item)
    }
  })

  const label = item?.label ?? ''
  const href = getHrefFromTransformedEntryData(item) ?? item?.href ?? ''

  const utagLinkEvent = (navLink, navLinkUrl) => {
    utagLink(
      [
        'link',
        {
          tealium_event: 'nav_meganav',
          nav_link: navLink,
          nav_link_url: navLinkUrl,
        },
      ],
      userSessionData?.dxlCountry,
      userSessionData?.dxlCurrency,
    )
  }

  return (
    <>
      {hasChildren ? (
        <>
          <Button
            aria-label="open-drawer"
            ref={btnRef}
            onClick={onOpen}
            variant="unstyled"
            width="100%"
            display="flex"
            justifyContent="space-between"
            height={'2.813rem'}
          >
            <Text
              width={'100%'}
              display={'flex'}
              alignContent={'center'}
              textStyle={{ base: 'body-75' }}
              my={3}
              alignSelf={'center'}
              color={'var(--semantic-text-primary, #15191C)'}
              fontWeight={700}
            >
              {label}
            </Text>
            <ChevronRightIcon fontSize={24} color={'heading'} />
          </Button>
          <Drawer isOpen={isOpen} onClose={closeAll} placement="right" size={'full'} finalFocusRef={btnRef}>
            <DrawerOverlay />
            <DrawerContent height={'100dvh'} animation="ease-in-out" transition="ease-in-out">
              <DrawerHeader
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'3rem'}
                mb={6}
                py={2}
                px={2}
                borderBottom="1px solid"
                borderBottomColor="surface.border"
              >
                <ChevronLeftIcon fontSize={24} color={'heading'} onClick={onClose} cursor={'pointer'} />
                <Text
                  width={'100%'}
                  fontWeight="bold"
                  display={'flex'}
                  justifyContent={'center'}
                  alignContent={'center'}
                  textStyle={'heading-desktop-100'}
                >
                  {label}
                </Text>
                <DrawerCloseButton
                  style={{
                    position: 'initial',
                    width: 'md',
                    height: 'md',
                    fontSize: 12,
                  }}
                  _hover={{
                    bg: 'none',
                  }}
                />
              </DrawerHeader>
              <DrawerBody py={0} px={4}>
                <ChakraLink
                  onClick={() => {
                    utagLinkEvent(label, href)
                  }}
                  href={href}
                >
                  <Text
                    width={'100%'}
                    display={'flex'}
                    alignContent={'center'}
                    textStyle={{ base: 'body-75' }}
                    textDecoration="underline"
                    textDecorationColor="text.primary"
                    my={3}
                  >
                    {formatMessage({ id: 'megamenu.mobile.viewAllLabel', values: { category: label } })}
                  </Text>
                </ChakraLink>
                <Accordion allowToggle mb={'lg'} gap={2} display={'flex'} flexDirection={'column'}>
                  <MegaDrawerItemGroup data={menuItemGroups} closeAll={closeAll} />

                  {menuItems.length > 0 &&
                    menuItems.map((item: any) => (
                      <AccordionItem
                        key={item?.uid}
                        _hover={{
                          bg: 'none',
                        }}
                        borderTop={0}
                        borderBottom="1px solid"
                        borderBottomColor="surface.highlight"
                      >
                        <MegaDrawerItem item={item} closeAll={closeAll} isSubItem />
                      </AccordionItem>
                    ))}
                </Accordion>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <ChakraLink
          onClick={() => {
            utagLinkEvent(label, href)
          }}
          href={href}
        >
          <Text
            width={'100%'}
            display={'flex'}
            alignContent={'center'}
            textStyle={{ base: 'body-75' }}
            color="red"
            fontWeight={700}
            my={3}
          >
            {label}
          </Text>
        </ChakraLink>
      )}
    </>
  )
}
