import { RefObject } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Text } from '@chakra-ui/react'

interface MegaMenuButtonProps {
  label: string
  onOpen: () => void
  btnRef: RefObject<HTMLButtonElement>
  isSubItem?: boolean
  isHiglighted?: boolean
}

export const MegaDrawerButton = ({ label, onOpen, btnRef }: MegaMenuButtonProps) => {
  return (
    <Button
      aria-label="open-drawer"
      ref={btnRef}
      onClick={onOpen}
      variant="unstyled"
      width="100%"
      display="flex"
      justifyContent="space-between"
      height={'2.813rem'}
    >
      <Text
        width={'100%'}
        display={'flex'}
        alignContent={'center'}
        textStyle={{ base: 'body-75' }}
        my={3}
        alignSelf={'center'}
        color={'var(--semantic-text-primary, #15191C)'}
      >
        {label}
      </Text>
      <ChevronRightIcon fontSize={24} color={'heading'} />
    </Button>
  )
}
